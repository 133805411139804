import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
// css
import "./GLEventsPartnersOffer.css";
// components
import config from "../../utils/config";
import MainSidebar from "../../layouts/main/MainSidebar";
import LogoGLEvents from "../../assets/logo-glevents.png";
import ModuleImage from "../../components/main/ModuleImage";
import ModalGLEventsImage1 from "../../components/admin-form/gl-events/ModalGLEventsImage1";
import ModalGLEventsImage2 from "../../components/admin-form/gl-events/ModalGLEventsImage2";
import ModalGLEventsLogo from "../../components/admin-form/gl-events/ModalGLEventsLogo";

export default function GLEventsPartnersOfferImages() {
    // all images of modules
    const [images, setImages] = useState(null);
    // individual state of each modules images
    const [imageGlEvents1, setImageGlEvents1] = useState(null);
    const [imageGlEvents2, setImageGlEvents2] = useState(null);

    // all images of companies
    const [imagesCompany, setImagesCompany] = useState(null);
    // individual state of each modules images
    const [logoSidebar, setLogoSidebar] = useState(null);

    // à remplacer par le setAuth
    const isEditable = true;

    /* Modal Module "Image1" */
    const [openModalImage1, setOpenModalImage1] = useState(false);
    const handleOpenModalImage1 = () => {
        setOpenModalImage1(!openModalImage1);
    };
    /* Modal Module "Image2" */
    const [openModalImage2, setOpenModalImage2] = useState(false);
    const handleOpenModalImage2 = () => {
        setOpenModalImage2(!openModalImage2);
    };
    /* Modal Logo Editable in sidebar */
    const [openModalLogo, setOpenModalLogo] = useState(false);
    const handleOpenModalLogo = () => {
        setOpenModalLogo(!openModalLogo);
    };

    //----------------------------------------------------------------------

    const requestImagesCompany = axios.get(
        `${config.BACK_URL}images-company/company-images`
    );
    const requestImages = axios.get(`${config.BACK_URL}images/images`);

    useEffect(() => {
        axios
            .all([requestImagesCompany, requestImages])
            .then((res) => {
                const responseImagesCompany = res[0].data;
                const responseImages = res[1].data;
                setImagesCompany(responseImagesCompany);
                setImages(responseImages);
            })
            .catch((error) => {
                console.log(error);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //----------------------------------------------------------------------

    const getImagesCompany = useCallback(() => {
        if (imagesCompany !== null) {
            imagesCompany.forEach((el) => {
                switch (el.type_name) {
                    case "GL Events Logo Sidebar":
                        setLogoSidebar(el);
                        break;
                    default:
                        break;
                }
            });
        }
    }, [imagesCompany]);

    const getModulesImages = useCallback(() => {
        if (images !== null) {
            images.forEach((el) => {
                switch (el.type_name) {
                    case "GL Events Image 1":
                        setImageGlEvents1(el);
                        break;
                    case "GL Events Image 2":
                        setImageGlEvents2(el);
                        break;
                    case "GL Events Logo Sidebar GLE_Video":
                        setLogoSidebar(el);
                        break;
                    default:
                        break;
                }
            });
        }
    }, [images]);

    useEffect(() => {
        if (imagesCompany !== null) {
            getImagesCompany();
        }
        if (images !== null) {
            getModulesImages();
        }
    }, [imagesCompany, getImagesCompany, images, getModulesImages]);

    //----------------------------------------------------------------------

    return (
        // * -------- Main page Parteners Offer IMAGES -------- *
        <div className="main-container-gle">
            {images !== null ? (
                <>
                    {/* SIDEBAR */}
                    <div className="sidebar-gle sidebar-color">
                        <MainSidebar
                            logoOnTop={LogoGLEvents}
                            widthLogoOnTop="150px"
                            logoEditable={logoSidebar ? logoSidebar.src : null}
                            isEditable={isEditable}
                            handleOpenModalLogo={handleOpenModalLogo}
                        />
                    </div>
                    {/* ADMIN PART - Edit modal */}
                    {openModalLogo ? (
                        <div
                            className={
                                openModalLogo ? "modal_shown" : "modal_hidden"
                            }
                        >
                            <ModalGLEventsLogo
                                handleOpenModalLogo={handleOpenModalLogo}
                                setLogoSidebar={setLogoSidebar}
                            />
                        </div>
                    ) : null}
                    <div className="container-content container-style">
                        {/* IMAGE 1 */}
                        <div className="container-image1 container-image-global">
                            {isEditable ? (
                                <button
                                    className="edit-button-gl-events-global edit-button-gl-events-image edit-button-blue"
                                    type="button"
                                    onClick={() => {
                                        handleOpenModalImage1();
                                    }}
                                >
                                    <i className="fa-solid fa-pen-to-square fa-xl" />
                                </button>
                            ) : null}
                            {imageGlEvents1 !== null ? (
                                <ModuleImage
                                    image={imageGlEvents1.src}
                                    alt="Image-1"
                                    width={1565}
                                    height={1002}
                                />
                            ) : (
                                "Aucune donnée disponible"
                            )}
                            {/* ADMIN PART - Edit modal */}
                            {openModalImage1 ? (
                                <div
                                    className={
                                        openModalImage1
                                            ? "modal_shown"
                                            : "modal_hidden"
                                    }
                                >
                                    <ModalGLEventsImage1
                                        handleOpenModalImage1={
                                            handleOpenModalImage1
                                        }
                                        setImageGlEvents1={setImageGlEvents1}
                                    />
                                </div>
                            ) : null}
                        </div>
                        {/* IMAGE 2 */}
                        <div className="container-image2 container-image-global">
                            {isEditable ? (
                                <button
                                    className="edit-button-gl-events-global edit-button-gl-events-image  edit-button-blue"
                                    type="button"
                                    onClick={() => {
                                        handleOpenModalImage2();
                                    }}
                                >
                                    <i className="fa-solid fa-pen-to-square fa-xl" />
                                </button>
                            ) : null}
                            {imageGlEvents2 !== null ? (
                                <ModuleImage
                                    image={imageGlEvents2.src}
                                    alt="Image-2"
                                    width={1565}
                                    height={1002}
                                />
                            ) : (
                                "Aucune donnée disponible"
                            )}
                            {/* ADMIN PART - Edit modal */}
                            {openModalImage2 ? (
                                <div
                                    className={
                                        openModalImage2
                                            ? "modal_shown"
                                            : "modal_hidden"
                                    }
                                >
                                    <ModalGLEventsImage2
                                        handleOpenModalImage2={
                                            handleOpenModalImage2
                                        }
                                        setImageGlEvents2={setImageGlEvents2}
                                    />
                                </div>
                            ) : null}
                        </div>
                    </div>
                </>
            ) : (
                "Aucune donnée disponible"
            )}
        </div>
    );
}

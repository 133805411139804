import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
// css
import "./GLEventsPartnersOffer.css";
// components
import config from "../../utils/config";
import MainSidebar from "../../layouts/main/MainSidebar";
import ModuleVideo from "../../components/section-top/ModuleVideo";
import LogoGLEvents from "../../assets/logo-glevents.png";
import ModalGLEventsLogoVideo from "../../components/admin-form/gl-events/ModalGLEventsLogoVideo";
import ModalGLEventsVideo1 from "../../components/admin-form/gl-events/ModalGLEventsVideo1";
import ModalGLEventsVideo2 from "../../components/admin-form/gl-events/ModalGLEventsVideo2";

export default function GLEventsPartnersOffer() {
    // state datas of module 'gle_parteners_offer' in the db
    const [videos, setVideos] = useState(null);

    // all images of companies
    const [imagesCompany, setImagesCompany] = useState(null);
    // individual state of each modules images
    const [logoSidebar, setLogoSidebar] = useState(null);

    // à remplacer par le setAuth
    const isEditable = true;

    /* Modal Logo Editable in sidebar */
    const [openModalLogo, setOpenModalLogo] = useState(false);
    const handleOpenModalLogo = () => {
        setOpenModalLogo(!openModalLogo);
    };
    /* Modal Module "Video" */
    const [openModalVideo1, setOpenModalVideo1] = useState(false);
    const handleOpenModalVideo1 = () => {
        setOpenModalVideo1(!openModalVideo1);
    };
    /* Modal Module "Video" */
    const [openModalVideo2, setOpenModalVideo2] = useState(false);
    const handleOpenModalVideo2 = () => {
        setOpenModalVideo2(!openModalVideo2);
    };

    //----------------------------------------------------------------------

    const requestImagesCompany = axios.get(
        `${config.BACK_URL}images-company/company-images`
    );
    const requestVideos = axios.get(
        `${config.BACK_URL}glevents/gle/parteners-offer`
    );

    useEffect(() => {
        axios
            .all([requestImagesCompany, requestVideos])
            .then((res) => {
                const responseImagesCompany = res[0].data;
                const responseVideos = res[1].data;
                setImagesCompany(responseImagesCompany);
                setVideos(responseVideos[0].content);
            })
            .catch((error) => {
                console.log(error);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //----------------------------------------------------------------------

    const getImagesCompany = useCallback(() => {
        if (imagesCompany !== null) {
            imagesCompany.forEach((el) => {
                switch (el.type_name) {
                    case "GL Events Logo Sidebar GLE_Video":
                        setLogoSidebar(el);
                        break;
                    default:
                        break;
                }
            });
        }
    }, [imagesCompany]);

    useEffect(() => {
        if (imagesCompany !== null) {
            getImagesCompany();
        }
    }, [imagesCompany, getImagesCompany]);

    //----------------------------------------------------------------------

    return (
        // * -------- Main page Parteners Offer -------- *
        <div className="main-container-gle">
            {videos !== null ? (
                <>
                    <div className="sidebar-gle sidebar-color">
                        <MainSidebar
                            logoOnTop={LogoGLEvents}
                            widthLogoOnTop="150px"
                            logoEditable={logoSidebar.src}
                            isEditable={isEditable}
                            handleOpenModalLogo={handleOpenModalLogo}
                        />
                    </div>
                    {/* ADMIN PART - Edit modal */}
                    {openModalLogo ? (
                        <div
                            className={
                                openModalLogo ? "modal_shown" : "modal_hidden"
                            }
                        >
                            <ModalGLEventsLogoVideo
                                handleOpenModalLogo={handleOpenModalLogo}
                                setLogoSidebar={setLogoSidebar}
                            />
                        </div>
                    ) : null}
                    <div className="container-content container-style">
                        {/* VIDEO 1 */}
                        <div className="container-image1 container-image-global">
                            {isEditable ? (
                                <button
                                    className="edit-button-gl-events-global edit-button-gl-events-image edit-button-blue"
                                    type="button"
                                    onClick={() => {
                                        handleOpenModalVideo1();
                                    }}
                                >
                                    <i className="fa-solid fa-pen-to-square fa-xl" />
                                </button>
                            ) : null}
                            <ModuleVideo
                                idVideo={videos.id_video_1}
                                width={1565}
                                height={1002}
                            />
                            {/* ADMIN PART - Edit modal */}
                            {openModalVideo1 ? (
                                <div
                                    className={
                                        openModalVideo1
                                            ? "modal_shown"
                                            : "modal_hidden"
                                    }
                                >
                                    <ModalGLEventsVideo1
                                        handleOpenModalVideo1={
                                            handleOpenModalVideo1
                                        }
                                        videos={videos}
                                        setVideos={setVideos}
                                    />
                                </div>
                            ) : null}
                        </div>

                        {/* VIDEO 2 */}
                        <div className="container-image2 container-image-global">
                            {isEditable ? (
                                <button
                                    className="edit-button-gl-events-global edit-button-gl-events-image  edit-button-blue"
                                    type="button"
                                    onClick={() => {
                                        handleOpenModalVideo2();
                                    }}
                                >
                                    <i className="fa-solid fa-pen-to-square fa-xl" />
                                </button>
                            ) : null}
                            <ModuleVideo
                                idVideo={videos.id_video_2}
                                width={1565}
                                height={1002}
                            />
                            {/* ADMIN PART - Edit modal */}
                            {openModalVideo2 ? (
                                <div
                                    className={
                                        openModalVideo2
                                            ? "modal_shown"
                                            : "modal_hidden"
                                    }
                                >
                                    <ModalGLEventsVideo2
                                        handleOpenModalVideo2={
                                            handleOpenModalVideo2
                                        }
                                        videos={videos}
                                        setVideos={setVideos}
                                    />
                                </div>
                            ) : null}
                        </div>
                    </div>
                </>
            ) : (
                "Aucune donnée disponible"
            )}
        </div>
    );
}

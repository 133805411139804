import React, { useState } from "react";
import PropTypes from "prop-types";
// components
import ModuleCard from "../../../components/main/ModuleCard";
import ModuleVideo from "../../../components/section-top/ModuleVideo";
import ModuleTwitter from "../../../components/section-top/ModuleTwitter";
import ModalFormFintechInsights from "../../../components/admin-form/laplace/ModalFormFintechInsights";
// Bg Circles Styled
import {
    CircleInsight1,
    CircleInsight2,
    CircleInsight3,
    CircleInsight4,
    CircleInsight5,
    CircleTwitter1,
    CircleTwitter2,
    CircleTwitter3,
    CircleTwitter4
} from "../../../layouts/circles/StyledCirclesContainer1";

/* ------------ CONTAINER 1 - TOP LEFT ------------ */

export default function Container1({
    idVideo,
    setIdVideo,
    cardTitle,
    handleCardTitle
}) {
    /* Modal Module "Fintech Insights" */
    const [openModalInsights, setOpenModalInsights] = useState(false);
    const handleOpenModalInsights = () => {
        setOpenModalInsights(!openModalInsights);
    };

    return (
        <div className="container1-top-left">
            {/* "Fintech Insights" */}
            {idVideo !== null ? (
                <div className="container1-child-left container-child-style padding-big-card">
                    <ModuleCard
                        title="Fintech Insights"
                        description="Lastest news"
                        isEditable
                        handleOpenModal={handleOpenModalInsights}
                        handleCardTitle={handleCardTitle}
                    >
                        <CircleInsight1 />
                        <CircleInsight2 />
                        <CircleInsight3 />
                        <CircleInsight4 />
                        <CircleInsight5 />
                        <ModuleVideo
                            idVideo={idVideo.id_video}
                            width={712.5}
                            height={396}
                        />
                    </ModuleCard>
                    {/* ADMIN PART - Edit modal */}
                    {openModalInsights ? (
                        <div
                            className={
                                openModalInsights
                                    ? "modal_shown"
                                    : "modal_hidden"
                            }
                        >
                            <ModalFormFintechInsights
                                handleOpenModalInsights={
                                    handleOpenModalInsights
                                }
                                cardTitle={cardTitle}
                                idVideo={idVideo.id_video}
                                setIdVideo={setIdVideo}
                            />
                        </div>
                    ) : null}
                </div>
            ) : (
                <div className="container1-child-left container-child-style padding-big-card">
                    <ModuleCard
                        title="Fintech Insights"
                        description="Lastest news"
                    >
                        <CircleInsight1 />
                        <CircleInsight2 />
                        <CircleInsight3 />
                        <CircleInsight4 />
                        <CircleInsight5 />
                        Aucune donnée disponible
                    </ModuleCard>
                </div>
            )}
            {/* "Twitter" */}
            <div className="container1-child-right container-child-style padding-big-card">
                <ModuleCard title="Twitter" description="@LaPlaceFintech">
                    <CircleTwitter1 />
                    <CircleTwitter2 />
                    <CircleTwitter3 />
                    <CircleTwitter4 />
                    <ModuleTwitter />
                </ModuleCard>
            </div>
        </div>
    );
}

Container1.propTypes = {
    idVideo: PropTypes.object,
    setIdVideo: PropTypes.func,
    cardTitle: PropTypes.string,
    handleCardTitle: PropTypes.func
};

import typography from "./typography";

export const theme = {
    palette: {
        primary: {
            lighter: "#C9D2F9",
            light: "#5D72DC",
            main: "#06188b",
            dark: "#030D64",
            darker: "#010642"
        },
        secondary: {
            lighter: "#FEF1DB",
            light: "#FCCA94",
            main: "#f8914d",
            dark: "#B24D26",
            darker: "#771F0E"
        },
        tertiary: {
            main: "#68a7d4"
        },
        quaternary: {
            main: "#fddc5a"
        },
        success: {
            main: "#25F500"
        },
        info: {
            main: "#00B0FF"
        },
        warning: {
            main: "#FBC02D"
        },
        danger: {
            main: "#ff1818"
        },
        common: {
            white: "#fff",
            black: "#000"
        },
        background: {
            card: "#fff",
            main: "#F8F8FA",
            sidebar: "#231f20"
        }
    },
    typography
};

import axios from "axios";
import config from "../utils/config";
import useAuth from "./useAuth";

const useRefreshToken = () => {
    const { setAuth } = useAuth();

    const refresh = async () => {
        const response = await axios.get(
            `${config.BACK_URL}auth/refresh-token`,
            { withCredentials: true }
        );
        const userName = {
            firstname: response?.data?.user.firstname,
            lastname: response?.data?.user.lastname
        };
        setAuth((prev) => ({
            ...prev,
            roles: response.data.roles,
            accessToken: response.data.accessToken,
            userName
        }));
        return response.data.accessToken;
    };
    return refresh;
};

export default useRefreshToken;

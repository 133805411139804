import React from "react";
import PropTypes from "prop-types";
// hooks
import styled from "styled-components";
import useAuth from "../../hooks/useAuth";
// style
import { theme } from "../../theme";

const StyledCard = styled.div`
    height: 100%;
    width: 100%;
    padding: 40px;
    border-radius: 42px;
    box-shadow: 5px 10px 30px rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    background-color: ${theme.palette.background.card};
`;

const ContentTitle = styled.span`
    display: flex;
    z-index: 10;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
`;

const Title = styled.h2`
    margin: 0px 0px;
    font-size: 25px;
    text-transform: uppercase;
`;

const Description = styled.p`
    margin: 0px 0px;
    text-transform: uppercase;
    opacity: 0.7;
`;

const Content = styled.span`
    height: 100%;
    width: 100%;
    z-index: 1;
`;

const ModuleCard = ({
    title,
    description,
    children,
    isEditable,
    isAddableCompany,
    isKpiCard,
    handleOpenModal,
    handleOpenModalAddCompanyFundings,
    handleCardTitle
}) => {
    const { auth } = useAuth();
    return (
        <>
            <StyledCard>
                <ContentTitle>
                    <Title>{title}</Title>
                    <Description>{description}</Description>
                </ContentTitle>
                <Content>{children}</Content>
                {isEditable && auth?.roles === "admin" ? (
                    <button
                        className={
                            isKpiCard
                                ? "edit-button-global edit-button-white"
                                : "edit-button-global edit-button-blue"
                        }
                        type="button"
                        onClick={() => {
                            handleOpenModal();
                            handleCardTitle(title);
                        }}
                    >
                        <i className="fa-solid fa-pen-to-square fa-xl" />
                    </button>
                ) : null}
                {isAddableCompany && auth?.roles === "admin" ? (
                    <button
                        className="edit-button-global edit-button-add-company edit-button-orange"
                        type="button"
                        onClick={() => {
                            handleOpenModalAddCompanyFundings();
                            handleCardTitle(title);
                        }}
                    >
                        <i className="fa-solid fa-plus fa-xl" />
                    </button>
                ) : null}
            </StyledCard>
        </>
    );
};
export default ModuleCard;

ModuleCard.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    isEditable: PropTypes.bool,
    isAddableCompany: PropTypes.bool,
    isKpiCard: PropTypes.bool,
    handleOpenModal: PropTypes.func,
    handleOpenModalAddCompanyFundings: PropTypes.func,
    handleCardTitle: PropTypes.func,
    children: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object,
        PropTypes.string
    ])
};

import styled from "styled-components";
import { theme } from "../../theme";

// CARD TRENDING FINTECH
export const CircleTrending1 = styled.span`
    border-radius: 50%;
    border: 3px solid ${theme.palette.primary.main};
    z-index: 0;
    height: 600px;
    width: 600px;
    position: absolute;
    bottom: -400px;
    right: -420px;
    opacity: 0.6;
`;
export const CircleTrending2 = styled.span`
    border-radius: 50%;
    border: 5px dashed ${theme.palette.tertiary.main};
    z-index: 0;
    height: 160px;
    width: 160px;
    position: absolute;
    bottom: -80px;
    right: 110px;
    opacity: 0.6;
`;
export const CircleTrending3 = styled.span`
    border-radius: 50%;
    background-color: ${theme.palette.tertiary.main};
    z-index: 0;
    height: 130px;
    width: 130px;
    position: absolute;
    top: -100px;
    right: 0px;
    opacity: 0.6;
`;
export const CircleTrending4 = styled.span`
    border-radius: 50%;
    background-color: ${theme.palette.quaternary.main};
    z-index: 0;
    height: 40px;
    width: 40px;
    position: absolute;
    top: 30px;
    left: 80px;
    opacity: 0.6;
`;
export const CircleTrending5 = styled.span`
    border-radius: 50%;
    border: 4px dotted ${theme.palette.secondary.main};
    z-index: 0;
    height: 240px;
    width: 240px;
    position: absolute;
    top: -130px;
    left: 100px;
    opacity: 0.6;
`;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import * as Yup from "yup";
// Form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { axiosPrivate } from "../../../api/axios";
// Hooks
// import useAuth from "../../hooks/useAuth";
// component
import config from "../../../utils/config";

const ModalFormEditCompanyFundings = ({
    handleOpenModalEditCompanyFundings,
    cardTitle,
    companies,
    setCompanies
}) => {
    // const { setAuth } = useAuth();
    const [uploadedImage, setUploadedImage] = useState("");
    const [companySelected, setCompanySelected] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [numberFundings, setNumberFundings] = useState("");

    const FundingsSchema = Yup.object().shape({
        uploadedImage: Yup.string().required("Une image est requise"),
        companyName: Yup.string().required("Un nom d'entreprise est requis"),
        numberFundings: Yup.number("Merci de remplir un nombre entre 0 et 1000")
            .min(0)
            .max(1000)
            .required("Merci de remplir un nombre entre 0 et 1000")
    });

    // Found the company selected in companies
    const companyFound = companies.find(
        (company) => company.name === companySelected
    );

    useEffect(() => {
        if (companyFound) {
            setCompanyName(companyFound.name);
        }
    }, [companyFound]);

    const {
        register,
        formState: { errors },
        handleSubmit
    } = useForm({
        mode: "change",
        defaultValues: { uploadedImage, companyName, numberFundings },
        resolver: yupResolver(FundingsSchema)
    });

    //----------------------------------------------------------------------

    const handleUploadedImage = (e) => {
        setUploadedImage(e.target.files[0]);
    };
    const handleCompanySelected = (e) => {
        setCompanySelected(e.target.value);
        setUploadedImage("");
        setNumberFundings(numberFundings);
    };

    //----------------------------------------------------------------------

    const onSubmit = async () => {
        const formData = new FormData();
        formData.append("image", uploadedImage);
        formData.append("type", "Logo Company");
        formData.append("company", companyName);
        formData.append("funding", numberFundings);
        formData.append("is_owner", 0);

        const configFile = {
            headers: { "Content-Type": "multipart/form-data" }
        };

        try {
            const response = await axiosPrivate.post(
                `${config.BACK_URL}/images-company/company-images`,
                formData,
                configFile
            );
            if (response.status === 200) {
                console.log(response);
                setCompanies(companies);
                handleOpenModalEditCompanyFundings();
            }
        } catch (error) {
            console.log(error);
        }
    };

    //----------------------------------------------------------------------

    return (
        <>
            <div className="modal_background">
                <div className="modal_card">
                    <h1 className="title">Edit a company</h1>
                    <h3 className="subtitle">{cardTitle}</h3>
                    <button
                        className="close_button"
                        type="button"
                        onClick={() => {
                            handleOpenModalEditCompanyFundings();
                        }}
                    >
                        <i className="fa-regular fa-circle-xmark fa-2xl" />
                    </button>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <p>Nom de l'entreprise :</p>
                        <select
                            name="companyName"
                            id="companyName"
                            {...register("companyName")}
                            onChange={handleCompanySelected}
                        >
                            <option value="">
                                Sélectionner une entreprise
                            </option>
                            {companies.map((company) => (
                                <option value={company.name} key={company.id}>
                                    {company.name}
                                </option>
                            ))}
                        </select>
                        {errors.companyName && (
                            <p className="error-message">
                                {errors.companyName.message}
                            </p>
                        )}
                        <p>Levée de fonds (en M€) :</p>
                        {companyFound ? (
                            <b className="text-bold">
                                {companyFound.total_fundraising_amount} M€
                            </b>
                        ) : null}
                        <input
                            type="number"
                            step={0.1}
                            min="0"
                            max="1000"
                            name="numberFundings"
                            id="numberFundings"
                            {...register("numberFundings")}
                        />
                        {errors.numberFundings && (
                            <p className="error-message">
                                {errors.numberFundings.message}
                            </p>
                        )}
                        <p>Sélectionner un fichier :</p>
                        <input
                            type="file"
                            accept="image/*"
                            name="uploadedImage"
                            {...register("uploadedImage")}
                            onChange={handleUploadedImage}
                        />
                        {companyFound && !uploadedImage ? (
                            <img
                                src={companyFound.src}
                                alt={companyFound.name}
                            />
                        ) : null}
                        {errors.uploadedImage && (
                            <p className="error-message">
                                {errors.uploadedImage.message}
                            </p>
                        )}
                        <button className="submit_button" type="submit">
                            Edit
                        </button>
                    </form>
                </div>
            </div>
        </>
    );
};

export default ModalFormEditCompanyFundings;

ModalFormEditCompanyFundings.propTypes = {
    handleOpenModalEditCompanyFundings: PropTypes.func,
    cardTitle: PropTypes.string,
    companies: PropTypes.array,
    setCompanies: PropTypes.func
};

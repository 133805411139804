import React, { useState } from "react";
import PropTypes from "prop-types";
// components
import ModuleCard from "../../../components/main/ModuleCard";
import MappingVC from "../../../components/section-top/MappingVC";
import ModuleCardData from "../../../components/main/ModuleCardData";
import ModuleVC from "../../../components/section-top/ModuleVC";
// modals
import ModalWeeklyData from "../../../components/admin-form/laplace/ModalWeeklyData";
import ModalFintechDatabase from "../../../components/admin-form/laplace/ModalFintechDatabase";
import ModalTotalVcInvest from "../../../components/admin-form/laplace/ModalTotalVcInvest";
import ModalMappingVc from "../../../components/admin-form/laplace/ModalMappingVc";
// Background colors diagonals
import { BgDiagonal } from "../../../layouts/bgcards/BgSmallCards";
// Bg Circles Styled
import {
    CircleMapping1,
    CircleMapping2,
    CircleMapping3
} from "../../../layouts/circles/StyledCirclesContainer2";

/* ------------ CONTAINER 2 - TOP RIGHT ------------ */

export default function Container2({
    weeklyData,
    setWeeklyData,
    vcInvestment,
    setVcInvestment,
    nbAdherents,
    fintechDatabase,
    setFintechDatabase,
    companies,
    cardTitle,
    handleCardTitle
}) {
    /* Modal Module "Weekly Data" */
    const [openModalWeekly, setOpenModalWeekly] = useState(false);
    const handleOpenModalWeekly = () => {
        setOpenModalWeekly(!openModalWeekly);
    };
    /* Modal Module "Fintech Database" */
    const [openModalDatabase, setOpenModalDatabase] = useState(false);
    const handleOpenModalDatabase = () => {
        setOpenModalDatabase(!openModalDatabase);
    };
    /* Modal Module "Total VC Investment" */
    const [openModalVcInvest, setOpenModalVcInvest] = useState(false);
    const handleOpenModalVcInvest = () => {
        setOpenModalVcInvest(!openModalVcInvest);
    };
    /* Modal Module "Mapping VC Portfolio" */
    const [openModalMappingVc, setOpenModalMappingVc] = useState(false);
    const handleOpenModalMappingVc = () => {
        setOpenModalMappingVc(!openModalMappingVc);
    };

    return (
        <div className="container2-top-right">
            {/* -------- 4 small cards data -------- */}
            <div className="container2-child-left">
                {/* "Weekly data" */}
                {weeklyData !== null ? (
                    <div className="container2-multicard-top-left container-child-style padding-small-card-top bg-color-small-card">
                        <ModuleCard
                            title="Weekly data"
                            description="Global Fintech"
                            isEditable
                            isKpiCard
                            handleOpenModal={handleOpenModalWeekly}
                            handleCardTitle={handleCardTitle}
                        >
                            <BgDiagonal />
                            <ModuleCardData
                                amount={`${weeklyData.amount}`}
                                sentence={weeklyData.sentence}
                                source={weeklyData.source}
                            />
                        </ModuleCard>
                        {/* ADMIN PART - Edit modal */}
                        {openModalWeekly ? (
                            <div
                                className={
                                    openModalWeekly
                                        ? "modal_shown"
                                        : "modal_hidden"
                                }
                            >
                                <ModalWeeklyData
                                    handleOpenModalWeekly={
                                        handleOpenModalWeekly
                                    }
                                    cardTitle={cardTitle}
                                    weeklyData={weeklyData}
                                    amount={weeklyData.amount}
                                    setWeeklyData={setWeeklyData}
                                />
                            </div>
                        ) : null}
                    </div>
                ) : (
                    <div className="container2-multicard-top-left container-child-style padding-small-card-top bg-color-small-card">
                        <ModuleCard
                            title="Weekly data"
                            description="Global Fintech"
                        >
                            <BgDiagonal />
                            "Aucune donnée disponible"
                        </ModuleCard>
                    </div>
                )}

                {/* "Total VC Investment" */}
                {vcInvestment !== null ? (
                    <div className="container2-multicard-top-right container-child-style padding-small-card-top bg-color-small-card">
                        <ModuleCard
                            title="Total VC Investment"
                            description="France"
                            isEditable
                            isKpiCard
                            handleOpenModal={handleOpenModalVcInvest}
                            handleCardTitle={handleCardTitle}
                        >
                            <BgDiagonal />
                            <ModuleVC
                                contentN_1={vcInvestment.amount_N_1}
                                sentenceN_1={vcInvestment.date_N_1}
                                sourceN_1={vcInvestment.source_N_1}
                                content_N={vcInvestment.amount_N}
                                sentence_N={vcInvestment.date_N}
                                source_N={vcInvestment.source_N}
                            />
                        </ModuleCard>
                        {/* ADMIN PART - Edit modal */}
                        {openModalVcInvest ? (
                            <div
                                className={
                                    openModalVcInvest
                                        ? "modal_shown"
                                        : "modal_hidden"
                                }
                            >
                                <ModalTotalVcInvest
                                    handleOpenModalVcInvest={
                                        handleOpenModalVcInvest
                                    }
                                    cardTitle={cardTitle}
                                    vcInvestment={vcInvestment}
                                    amount_N={vcInvestment.amount_N}
                                    amount_N_1={vcInvestment.amount_N_1}
                                    setVcInvestment={setVcInvestment}
                                />
                            </div>
                        ) : null}
                    </div>
                ) : (
                    <div className="container2-multicard-top-right container-child-style padding-small-card-top bg-color-small-card">
                        <ModuleCard
                            title="Total VC Investment"
                            description="France"
                        >
                            <BgDiagonal />
                            "Aucune donnée disponible"
                        </ModuleCard>
                    </div>
                )}

                {/* "Adherents" */}
                <div className="container2-multicard-bottom-left container-child-style padding-small-card-bottom bg-color-small-card">
                    <ModuleCard title="Adherents" description="Global Fintech">
                        <BgDiagonal />
                        {nbAdherents !== null ? (
                            <ModuleCardData
                                amount={nbAdherents.amount}
                                sentence={nbAdherents.description}
                                source={nbAdherents.source}
                            />
                        ) : (
                            "Aucune donnée disponible"
                        )}
                    </ModuleCard>
                </div>

                {/* "Fintech Database" */}
                {fintechDatabase !== null ? (
                    <div className="container2-multicard-bottom-right container-child-style padding-small-card-bottom bg-color-small-card">
                        <ModuleCard
                            title="Fintech Database"
                            description="France year 2022"
                            isEditable
                            isKpiCard
                            handleOpenModal={handleOpenModalDatabase}
                            handleCardTitle={handleCardTitle}
                        >
                            <BgDiagonal />
                            <ModuleCardData
                                amount={fintechDatabase.amount}
                                sentence={fintechDatabase.sentence}
                                source={fintechDatabase.source}
                            />
                        </ModuleCard>
                        {/* ADMIN PART - Edit modal */}
                        {openModalDatabase ? (
                            <div
                                className={
                                    openModalDatabase
                                        ? "modal_shown"
                                        : "modal_hidden"
                                }
                            >
                                <ModalFintechDatabase
                                    handleOpenModalDatabase={
                                        handleOpenModalDatabase
                                    }
                                    cardTitle={cardTitle}
                                    fintechDatabase={fintechDatabase}
                                    amount={fintechDatabase.amount}
                                    setFintechDatabase={setFintechDatabase}
                                />
                            </div>
                        ) : null}
                    </div>
                ) : (
                    <div className="container2-multicard-bottom-right container-child-style padding-small-card-bottom bg-color-small-card">
                        <ModuleCard
                            title="Fintech Database"
                            description="France year 2022"
                        >
                            <BgDiagonal />
                            "Aucune donnée disponible"
                        </ModuleCard>
                    </div>
                )}
            </div>

            {/* ---- Large Card - "Mapping VC Portfolio" ---- */}
            {companies !== null ? (
                <div className="container2-child-right container-child-style padding-big-card">
                    <ModuleCard
                        title="Mapping VC Portfolio"
                        description="France Year 2022"
                        isEditable
                        handleOpenModal={handleOpenModalMappingVc}
                        handleCardTitle={handleCardTitle}
                    >
                        <CircleMapping1 />
                        <CircleMapping2 />
                        <CircleMapping3 />
                        <MappingVC companies={companies} />
                    </ModuleCard>
                    {/* ADMIN PART - Edit modal */}
                    {openModalMappingVc ? (
                        <div
                            className={
                                openModalMappingVc
                                    ? "modal_shown"
                                    : "modal_hidden"
                            }
                        >
                            <ModalMappingVc
                                handleOpenModalMappingVc={
                                    handleOpenModalMappingVc
                                }
                                cardTitle={cardTitle}
                                companies={companies}
                            />
                        </div>
                    ) : null}
                </div>
            ) : (
                <div className="container2-child-right container-child-style padding-big-card">
                    <ModuleCard
                        title="Mapping VC Portfolio"
                        description="France Year 2022"
                    >
                        <CircleMapping1 />
                        <CircleMapping2 />
                        <CircleMapping3 />
                        "Aucune donnée disponible"
                    </ModuleCard>
                </div>
            )}
        </div>
    );
}

Container2.propTypes = {
    weeklyData: PropTypes.object,
    setWeeklyData: PropTypes.func,
    vcInvestment: PropTypes.object,
    setVcInvestment: PropTypes.func,
    nbAdherents: PropTypes.object,
    fintechDatabase: PropTypes.object,
    setFintechDatabase: PropTypes.func,
    companies: PropTypes.array,
    setCompanies: PropTypes.func,
    cardTitle: PropTypes.string,
    handleCardTitle: PropTypes.func
};

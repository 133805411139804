import { createGlobalStyle } from "styled-components";
import { theme } from "../theme";

const GlobalStyles = createGlobalStyle`

  body {
    font-family: ${theme.typography.fontFamily};
    background-color: ${theme.palette.background.neutral};
  }

  .sidebar-color {
    background-color: ${theme.palette.background.sidebar};
  }
  
  p {
    // opacity: 0.6;
    line-height: 1.5;
  }
}`;

export default GlobalStyles;

import React, { useEffect, useState } from "react";
import styled from "styled-components";
// components
import useLogout from "../../hooks/useLogout";
import useAuth from "../../hooks/useAuth";

const MainContent = styled.div`
    position: absolute;
    top: 260px;
`;
const TextSentence = styled.p`
    color: white;
    font-size: 14px;
    margin: 1px;
`;
const TextAdmin = styled.p`
    color: white;
    font-size: 18px;
    margin: 3px;
    text-transform: uppercase;
`;
const ButtonLogout = styled.button`
    margin-top: 10px;
    justify-content: center;
    z-index: 11;
    border-radius: 50%;
    height: 42px;
    width: 42px;
    cursor: pointer;
    box-shadow: 5px 3px 10px rgba(0, 0, 0, 0.5);
    background-color: #ff1818;
    border: 1px solid #ff1818;
    color: white;
`;

const LogoutForm = () => {
    const { auth } = useAuth();
    const logout = useLogout();

    const [adminName, setAdminName] = useState("");

    const signOut = async () => {
        await logout();
    };

    useEffect(() => {
        if (auth !== null) {
            setAdminName(auth.userName);
        } else {
            setAdminName("");
        }
    }, [auth]);

    return (
        auth?.roles === "admin" && (
            <MainContent>
                <TextSentence>Connecté en tant que :</TextSentence>
                <TextSentence>({auth.roles})</TextSentence>
                <TextAdmin>
                    {adminName.firstname} {adminName.lastname}
                </TextAdmin>
                <div>
                    <ButtonLogout type="button" onClick={signOut}>
                        <i className="fa-solid fa-power-off fa-xl" />
                    </ButtonLogout>
                </div>
            </MainContent>
        )
    );
};

export default LogoutForm;

import React, { useState } from "react";
import PropTypes from "prop-types";
import * as Yup from "yup";
// Form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// Hooks
// import useAuth from "../../hooks/useAuth";
// component
import config from "../../../utils/config";
import { axiosPrivate } from "../../../api/axios";

const ModalGLEventsLogoVideo = ({ handleOpenModalLogo, setLogoSidebar }) => {
    // const { setAuth } = useAuth();
    const [uploadedImage, setUploadedImage] = useState("");
    const [companyName, setCompanyName] = useState("");

    const GleLogoSchema = Yup.object().shape({
        uploadedImage: Yup.string().required("Une image est requise"),
        companyName: Yup.string().required("Un nom d'entreprise est requis")
    });

    const {
        register,
        formState: { errors },
        handleSubmit
    } = useForm({
        mode: "change",
        defaultValues: { uploadedImage, companyName },
        resolver: yupResolver(GleLogoSchema)
    });

    //----------------------------------------------------------------------

    const handleUploadedImage = (e) => {
        setUploadedImage(e.target.files[0]);
    };
    const handleCompanyName = (e) => {
        setCompanyName(e.target.value);
    };

    const onSubmit = async () => {
        const formData = new FormData();
        formData.append("image", uploadedImage);
        formData.append("type", "GL Events Logo Sidebar GLE_Video");
        formData.append("company", companyName);
        formData.append("is_owner", 0);

        const configFile = {
            headers: { "Content-Type": "multipart/form-data" }
        };

        try {
            const response = await axiosPrivate.post(
                `${config.BACK_URL}/images-company/company-images`,
                formData,
                configFile
            );
            console.log(response);
            if (response.status === 200) {
                setLogoSidebar({ src: response.data.src });
                handleOpenModalLogo();
            }
        } catch (error) {
            console.log(error);
        }
    };

    //----------------------------------------------------------------------

    return (
        <>
            <div className="modal_background">
                <div className="modal_card">
                    <h1 className="title">Edit Form</h1>
                    <h3 className="subtitle">GL Events - Logo Sidebar Vidéo</h3>
                    <button
                        className="close_button"
                        type="button"
                        onClick={() => {
                            handleOpenModalLogo();
                        }}
                    >
                        <i className="fa-regular fa-circle-xmark fa-2xl" />
                    </button>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <p>Nom de l'entreprise :</p>
                        <input
                            type="text"
                            name="companyName"
                            {...register("companyName")}
                            onChange={handleCompanyName}
                        />
                        {errors.companyName && (
                            <p className="error-message">
                                {errors.companyName.message}
                            </p>
                        )}
                        <p>Sélectionner un fichier :</p>
                        <input
                            type="file"
                            accept="image/*"
                            name="uploadedImage"
                            {...register("uploadedImage")}
                            onChange={handleUploadedImage}
                        />
                        {errors.uploadedImage && (
                            <p className="error-message">
                                {errors.uploadedImage.message}
                            </p>
                        )}
                        <button className="submit_button" type="submit">
                            Save
                        </button>
                    </form>
                </div>
            </div>
        </>
    );
};

export default ModalGLEventsLogoVideo;

ModalGLEventsLogoVideo.propTypes = {
    handleOpenModalLogo: PropTypes.func,
    setLogoSidebar: PropTypes.func
};

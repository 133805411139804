import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { theme } from "../../theme";
import { fCurrency, fPercent } from "../../utils/formatNumber";

const DataContent = styled.h3`
    font-weight: 600;
    font-size: 40px;
    margin: 40px 0px 0px 0px;
    color: ${theme.palette.common.white};
`;

const PercentPositive = styled.p`
    color: ${theme.palette.success.main};
    margin: 30px 0px 10px 0px;
    text-align: center;
    font-weight: 600;
    font-size: 20px;
    border: 2px solid RGBA(0, 200, 83, 0.2);
    border-radius: 12px;
    padding: 4px 10px;
    background-color: RGBA(0, 200, 83, 0.45);
`;

const PercentNegative = styled.p`
    color: ${theme.palette.danger.main};
    margin: 30px 0px 10px 0px;
    text-align: center;
    font-weight: 600;
    font-size: 20px;
    border: 1px solid rgba(179, 0, 0, 0.1);
    border-radius: 12px;
    padding: 4px 10px;
    background-color: rgba(179, 0, 0, 0.3);
`;
const Sentence = styled.p`
    margin: 0px;
    text-align: center;
    opacity: 0.7;
`;

const LogoBg = styled.img`
    position: absolute;
    top: -110px;
    right: -75px;
    height: 230px;
    opacity: 0.25;
`;

// Evolution in % - between now and last week
const percentProgress = (price_current, price_lastweek) =>
    ((price_current - price_lastweek) / price_lastweek) * 100;

//----------------------------------------------------------------------

const ModuleCardCrypto = ({ price_current, price_lastweek, logo }) => (
    <>
        <LogoBg src={logo} alt="Logo cryptocurrency" />
        <DataContent>
            {fCurrency(price_current) || "En attente de données"}
        </DataContent>
        {percentProgress(price_current, price_lastweek) > 0 ? (
            <PercentPositive>
                {fPercent(percentProgress(price_current, price_lastweek))}
            </PercentPositive>
        ) : (
            <PercentNegative>
                {fPercent(percentProgress(price_current, price_lastweek))}
            </PercentNegative>
        )}
        <Sentence>From last week</Sentence>
    </>
);

export default ModuleCardCrypto;

ModuleCardCrypto.propTypes = {
    price_current: PropTypes.number,
    price_lastweek: PropTypes.number,
    logo: PropTypes.string
};

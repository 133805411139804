import React from "react";
import PropTypes from "prop-types";
import * as Yup from "yup";
// Form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// Hooks
// import useAuth from "../../../hooks/useAuth";
// component
import config from "../../../utils/config";
import { axiosPrivate } from "../../../api/axios";
// css
import "../ModalForm.css";

const ModalTotalVcInvest = ({
    handleOpenModalVcInvest,
    cardTitle,
    vcInvestment,
    setVcInvestment
}) => {
    // const { setAuth } = useAuth();

    const VcInvestSchema = Yup.object().shape({
        amount_N: Yup.string().required("Une donnée est requise"),
        amount_N_1: Yup.string().required("Une donnée est requise"),
        date_N: Yup.string().required("Une donnée est requise"),
        date_N_1: Yup.string().required("Une donnée est requise"),
        source_N: Yup.string().required("Une donnée est requise"),
        source_N_1: Yup.string().required("Une donnée est requise")
    });

    const {
        register,
        formState: { errors },
        handleSubmit
    } = useForm({
        mode: "onChange",
        defaultValues: {
            amount_N: vcInvestment.amount_N,
            amount_N_1: vcInvestment.amount_N_1,
            date_N: vcInvestment.date_N,
            date_N_1: vcInvestment.date_N_1,
            source_N: vcInvestment.source_N,
            source_N_1: vcInvestment.source_N_1
        },
        resolver: yupResolver(VcInvestSchema)
    });

    //----------------------------------------------------------------------

    const onSubmit = async (values) => {
        try {
            const response = await axiosPrivate.post(
                `${config.BACK_URL}/admin/total-vc-investment`,
                values
            );
            if (response.status === 200) {
                setVcInvestment(values);
                handleOpenModalVcInvest();
            }
            // const { accessToken } = response.data;
            // const { roles } = response.data;
            // setAuth({ amount, roles, accessToken });
        } catch (error) {
            console.log(error);
        }
    };

    //----------------------------------------------------------------------

    return (
        <>
            <div className="modal_background">
                <div className="modal_card">
                    <h1 className="title">Edit Form</h1>
                    <h3 className="subtitle">{cardTitle}</h3>
                    <button
                        className="close_button"
                        type="button"
                        onClick={() => {
                            handleOpenModalVcInvest();
                        }}
                    >
                        <i className="fa-regular fa-circle-xmark fa-2xl" />
                    </button>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        {/* Datas N */}
                        <p className="label">Montant N (en M€) :</p>
                        <input
                            type="text"
                            id="amount_N"
                            {...register("amount_N")}
                        />
                        {errors.amount_N && (
                            <p className="error-message">
                                {errors.amount_N.message}
                            </p>
                        )}
                        <p className="label">Date N :</p>
                        <input
                            type="text"
                            id="date_N"
                            {...register("date_N")}
                        />
                        {errors.date_N && (
                            <p className="error-message">
                                {errors.date_N.message}
                            </p>
                        )}
                        <p className="label">Source N :</p>
                        <input
                            type="text"
                            id="source_N"
                            {...register("source_N")}
                        />
                        {errors.source_N && (
                            <p className="error-message">
                                {errors.source_N.message}
                            </p>
                        )}
                        {/* Datas N-1 */}
                        <p className="label">Montant N-1 (en M€) :</p>
                        <input
                            type="text"
                            id="amount_N_1"
                            {...register("amount_N_1")}
                        />
                        {errors.amount_N_1 && (
                            <p className="error-message">
                                {errors.amount_N_1.message}
                            </p>
                        )}
                        <p className="label">Date N-1 :</p>
                        <input
                            type="text"
                            id="date_N_1"
                            {...register("date_N_1")}
                        />
                        {errors.date_N_1 && (
                            <p className="error-message">
                                {errors.date_N_1.message}
                            </p>
                        )}
                        <p className="label">Source N-1 :</p>
                        <input
                            type="text"
                            id="source_N_1"
                            {...register("source_N_1")}
                        />
                        {errors.source_N_1 && (
                            <p className="error-message">
                                {errors.source_N_1.message}
                            </p>
                        )}
                        <button className="submit_button" type="submit">
                            Save
                        </button>
                    </form>
                </div>
            </div>
        </>
    );
};

export default ModalTotalVcInvest;

ModalTotalVcInvest.propTypes = {
    handleOpenModalVcInvest: PropTypes.func,
    cardTitle: PropTypes.string,
    vcInvestment: PropTypes.object,
    setVcInvestment: PropTypes.func
};

import { Outlet } from "react-router-dom";
import { useState, useEffect } from "react";
import useRefreshToken from "../../hooks/useRefreshToken";
import useAuth from "../../hooks/useAuth";

const PersistLogin = () => {
    const [isLoading, setIsLoading] = useState(true);
    const refresh = useRefreshToken();
    const { auth, persist } = useAuth();

    useEffect(() => {
        let isMounted;

        const verifyRefreshToken = async () => {
            try {
                await refresh();
            } catch (err) {
                console.error(err);
            } finally {
                isMounted = true;
                setIsLoading(false);
            }
        };

        // Avoids unwanted call to verifyRefreshToken
        if (!auth?.accessToken && persist) {
            verifyRefreshToken();
        } else {
            setIsLoading(false);
        }

        return () => (isMounted = false);
    }, []);

    // useEffect(() => {
    //     console.log(`isLoading: ${isLoading}`);
    //     console.log(`accessToken: ${JSON.stringify(auth?.accessToken)}`);
    // }, [isLoading]);

    //-------------------------------------------------------------------

    return <>{!isLoading ? <Outlet /> : <p>Loading...</p>}</>;
};

export default PersistLogin;

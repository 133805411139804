import React, { useState } from "react";
import PropTypes from "prop-types";
import * as Yup from "yup";
// Form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// Hooks
// import useAuth from "../../hooks/useAuth";
// component
import config from "../../../utils/config";
import { axiosPrivate } from "../../../api/axios";

const ModalImageMarketAnalysis = ({
    handleOpenModalAnalysis,
    cardTitle,
    setImageMarketAnalysis
}) => {
    // const { setAuth } = useAuth();
    const [uploadedImage, setUploadedImage] = useState("");

    const AnalysisSchema = Yup.object().shape({
        uploadedImage: Yup.string().required("Une image est requise")
    });

    const {
        register,
        formState: { errors },
        handleSubmit
    } = useForm({
        mode: "change",
        defaultValues: { uploadedImage },
        resolver: yupResolver(AnalysisSchema)
    });

    //----------------------------------------------------------------------

    const handleUploadedImage = (e) => {
        setUploadedImage(e.target.files[0]);
    };

    const onSubmit = async () => {
        const formData = new FormData();
        formData.append("image", uploadedImage);
        formData.append("type", "Module Market Analysis");

        const configFile = {
            headers: { "Content-Type": "multipart/form-data" }
        };

        try {
            const response = await axiosPrivate.post(
                `${config.BACK_URL}/images/images`,
                formData,
                configFile
            );
            if (response.status === 200) {
                setImageMarketAnalysis({ src: response.data.src });
                handleOpenModalAnalysis();
            }
        } catch (error) {
            console.log(error);
        }
    };

    //----------------------------------------------------------------------

    return (
        <>
            <div className="modal_background">
                <div className="modal_card">
                    <h1 className="title">Edit Form</h1>
                    <h3 className="subtitle">{cardTitle}</h3>
                    <button
                        className="close_button"
                        type="button"
                        onClick={() => {
                            handleOpenModalAnalysis();
                        }}
                    >
                        <i className="fa-regular fa-circle-xmark fa-2xl" />
                    </button>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <p>Sélectionner un fichier</p>
                        <input
                            type="file"
                            accept="image/png, image/jpeg"
                            name="uploadedImage"
                            {...register("uploadedImage")}
                            onChange={handleUploadedImage}
                        />
                        {errors.uploadedImage && (
                            <p className="error-message">
                                {errors.uploadedImage.message}
                            </p>
                        )}
                        <button className="submit_button" type="submit">
                            Save
                        </button>
                    </form>
                </div>
            </div>
        </>
    );
};

export default ModalImageMarketAnalysis;

ModalImageMarketAnalysis.propTypes = {
    handleOpenModalAnalysis: PropTypes.func,
    cardTitle: PropTypes.string,
    setImageMarketAnalysis: PropTypes.func
};

import styled from "styled-components";
import { theme } from "../../theme";

export const BgDiagonal = styled.span`
    background-color: ${theme.palette.tertiary.main};
    height: 400px;
    width: 400px;
    position: absolute;
    transform: rotate(55deg);
    top: 300px;
    left: 65px;
    opacity: 0.9;
    z-index: -10;
`;

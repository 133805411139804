import { ThemeProvider } from "styled-components";
import GlobalStyles from "./components/GlobalStyles";
// components
import Router from "./routes";
import { theme } from "./theme";

function App() {
    return (
        <ThemeProvider theme={theme}>
            <GlobalStyles />
            <Router />
        </ThemeProvider>
    );
}

export default App;

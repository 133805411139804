import styled from "styled-components";
import { theme } from "../../theme";

// CARD FUNDING INSIGHTS
export const CircleFunding1 = styled.span`
    border-radius: 50%;
    border: 1px dashed ${theme.palette.secondary.main};
    z-index: 0;
    height: 800px;
    width: 800px;
    position: absolute;
    top: -550px;
    left: -650px;
    opacity: 0.6;
`;
export const CircleFunding2 = styled.span`
    border-radius: 50%;
    background-color: ${theme.palette.tertiary.main};
    z-index: 0;
    height: 30px;
    width: 30px;
    position: absolute;
    top: -60px;
    right: -20px;
    opacity: 0.6;
`;
export const CircleFunding3 = styled.span`
    border-radius: 50%;
    border: 8px solid ${theme.palette.primary.main};
    z-index: 0;
    height: 250px;
    width: 250px;
    position: absolute;
    top: -200px;
    right: -100px;
    opacity: 0.6;
`;

// CARD MARKET ANALYSIS
export const CircleMarket1 = styled.span`
    border-radius: 50%;
    border: 5px dashed ${theme.palette.tertiary.main};
    z-index: 0;
    height: 800px;
    width: 800px;
    position: absolute;
    bottom: -250px;
    left: -500px;
`;
export const CircleMarket2 = styled.span`
    border-radius: 50%;
    background-color: ${theme.palette.primary.main};
    z-index: 0;
    height: 200px;
    width: 200px;
    position: absolute;
    bottom: -80px;
    right: -80px;
    opacity: 0.6;
`;
export const CircleMarket3 = styled.span`
    border-radius: 50%;
    border: 12px dotted ${theme.palette.secondary.main};
    z-index: 0;
    height: 200px;
    width: 200px;
    position: absolute;
    top: -170px;
    right: -20px;
    opacity: 0.6;
`;
export const CircleMarket4 = styled.span`
    border-radius: 50%;
    border: 3px solid ${theme.palette.quaternary.main};
    z-index: 0;
    height: 300px;
    width: 300px;
    position: absolute;
    top: -50px;
    right: -180px;
    opacity: 0.6;
`;

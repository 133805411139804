import React from "react";
import PropTypes from "prop-types";
import * as Yup from "yup";
// Form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { axiosPrivate } from "../../../api/axios";
// Hooks
// import useAuth from "../../hooks/useAuth";
// component
import config from "../../../utils/config";

const ModalMappingVc = ({ handleOpenModalMappingVc, cardTitle, companies }) => {
    // const { setAuth } = useAuth();

    const MappingSchema = Yup.object().shape({
        name: Yup.string().required("Un nom d'entreprise est requis"),
        is_owner: Yup.string().required("Un type d'entreprise est requis")
    });

    const {
        register,
        formState: { errors },
        handleSubmit
    } = useForm({
        mode: "change",
        defaultValues: {
            name: "",
            is_owner: ""
        },
        resolver: yupResolver(MappingSchema)
    });

    //----------------------------------------------------------------------

    const onSubmit = async (values) => {
        try {
            console.log(values);
            const response = await axiosPrivate.post(
                `${config.BACK_URL}/images-company/company-is-owner`,
                values
            );
            if (response.status === 200) {
                // setCompanies(...companies);
                handleOpenModalMappingVc();
            }
        } catch (error) {
            console.log(error);
        }
    };

    //----------------------------------------------------------------------

    return (
        <>
            <div className="modal_background">
                <div className="modal_card">
                    <h1 className="title">Edit a company</h1>
                    <h3 className="subtitle">{cardTitle}</h3>
                    <button
                        className="close_button"
                        type="button"
                        onClick={() => {
                            handleOpenModalMappingVc();
                        }}
                    >
                        <i className="fa-regular fa-circle-xmark fa-2xl" />
                    </button>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <p>Nom de l'entreprise :</p>
                        <select name="name" id="name" {...register("name")}>
                            <option value="">
                                Sélectionner une entreprise
                            </option>
                            {companies &&
                                companies.map((company) => (
                                    <option
                                        value={company.name}
                                        key={company.id}
                                    >
                                        {company.name}
                                    </option>
                                ))}
                        </select>
                        {errors.name && (
                            <p className="error-message">
                                {errors.name.message}
                            </p>
                        )}
                        <p>VC ou Porte-feuille :</p>
                        <select
                            name="is_owner"
                            id="is_owner"
                            {...register("is_owner")}
                        >
                            <option value="">Sélectionner un type</option>
                            <option value="1">VC</option>
                            <option value="2">Porte-feuille</option>
                            <option value="0">Aucun</option>
                        </select>
                        {errors.is_owner && (
                            <p className="error-message">
                                {errors.is_owner.message}
                            </p>
                        )}
                        <button className="submit_button" type="submit">
                            Edit
                        </button>
                    </form>
                </div>
            </div>
        </>
    );
};

export default ModalMappingVc;

ModalMappingVc.propTypes = {
    handleOpenModalMappingVc: PropTypes.func,
    cardTitle: PropTypes.string,
    companies: PropTypes.array
};

import { replace } from "lodash";
import numeral from "numeral";

// load a locale
numeral.register("locale", "fr", {
    delimiters: {
        thousands: " ",
        decimal: ","
    },
    abbreviations: {
        thousand: "k",
        million: "m",
        billion: "b",
        trillion: "t"
    },
    ordinal(number) {
        return number === 1 ? "er" : "ème";
    },
    currency: {
        symbol: "€"
    }
});

numeral.locale("en");

export function fCurrency(number) {
    return numeral(number).format("0,0.[00] $");
}
export function fNoSymbolCurrency(number) {
    return numeral(number).format("0,0.[00]");
}
export function fSignCurrency(number) {
    return numeral(number).format(
        Math.sign(number) ? "+0,0.[00] $" : "0,0.[00] $"
    );
}

export function fNoSignCurrency(number) {
    return numeral(number).format("0,0$");
}

export function fPercent(number) {
    if (number === 0) {
        return "0%";
    }
    return Math.sign(number)
        ? numeral(number / 100).format("+0.0 %")
        : numeral(number / 100).format("-0 %");
}

export function fNoSignPercent(number) {
    if (number === 0) {
        return "0%";
    }
    return Math.sign(number)
        ? numeral(number / 100).format("0.0 %")
        : numeral(number / 100).format("0 %");
}

export function fNumber(number) {
    return numeral(number).format("0.0");
}

export function fShortenNumber(number) {
    return replace(numeral(number).format("$0.00a"), "$.00", "");
}

export function kFormatter(num) {
    return Math.abs(num) > 999
        ? `${Math.sign(num) * (Math.abs(num) / 1000).toFixed(1)}k`
        : Math.sign(num) * Math.abs(num);
}

export function signFormater(num) {
    if (num === 0) {
        return num;
    }
    return Math.sign(num) === 1 ? `+ ${num}` : `- ${num}`;
}

export function addSpaceFormater(num) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { theme } from "../../theme";

const Number = styled.h4`
    font-size: 29px;
    color: ${theme.palette.common.white};
    text-shadow: 1px 2px 4px grey;
    opacity: 0.8;
    position: absolute;
    top: 0px;
`;

const LogoBg = styled.img`
    width: 120px;
    margin-bottom: 40px;
`;

const Company = styled.h3`
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
    color: ${theme.palette.common.white};
    position: absolute;
    bottom: 10px;
    opacity: 0.65;
`;

const MainContent = styled.div`
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    padding: 40px;
`;

const ContentFirstCompany = styled.div`
    width: 130px;
    box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.15);
    border: 1px solid white;
    border-radius: 36px 36px 0px 0px;
    padding: 290px 20px 100px 20px;
    background: #231f20;
    background-color: #231f20;
    background-color: #27359b;
    background-color: rgb(6, 24, 139, 0.85);
`;
const ContentSecondCompany = styled.div`
    width: 130px;
    box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(6, 24, 139, 0.08);
    border-radius: 36px 36px 0px 0px;
    padding: 140px 20px 100px 20px;
    background: #231f20;
    background-color: #231f20;
    background-color: #27359b;
    background-color: rgb(6, 24, 139, 0.85);
`;
const ContentThirdCompany = styled.div`
    width: 130px;
    box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(6, 24, 139, 0.08);
    border-radius: 36px 36px 0px 0px;
    padding: 80px 20px 100px 20px;
    background: #231f20;
    background-color: #231f20;
    background-color: #27359b;
    background-color: rgb(6, 24, 139, 0.85);
`;

//----------------------------------------------------------------------

const ModuleGradingFunding = ({ companies }) => {
    function compare(a, b) {
        return b.total_fundraising_amount - a.total_fundraising_amount;
    }
    companies.sort(compare);

    return (
        <>
            <MainContent>
                <div>
                    <LogoBg src={companies[1].src} alt="Logo second company" />
                    <ContentSecondCompany>
                        <Number>
                            {companies[1].total_fundraising_amount ||
                                "En attente de données"}
                            M€
                        </Number>
                        <Company>
                            {companies[1].name || "En attente de données"}
                        </Company>
                    </ContentSecondCompany>
                </div>
                <div>
                    <LogoBg src={companies[0].src} alt="Logo first company" />
                    <ContentFirstCompany>
                        <Number>
                            {companies[0].total_fundraising_amount ||
                                "En attente de données"}
                            M€
                        </Number>
                        <Company>
                            {companies[0].name || "En attente de données"}
                        </Company>
                    </ContentFirstCompany>
                </div>
                <div>
                    <LogoBg src={companies[2].src} alt="Logo third company" />
                    <ContentThirdCompany>
                        <Number>
                            {companies[2].total_fundraising_amount ||
                                "En attente de données"}
                            M€
                        </Number>
                        <Company>
                            {companies[2].name || "En attente de données"}
                        </Company>
                    </ContentThirdCompany>
                </div>
            </MainContent>
        </>
    );
};

export default ModuleGradingFunding;

ModuleGradingFunding.propTypes = {
    companies: PropTypes.array
};

import React, { useState } from "react";
import PropTypes from "prop-types";
// components
import ModuleCard from "../../../components/main/ModuleCard";
import ModuleImage from "../../../components/main/ModuleImage";
import ModuleCardCrypto from "../../../components/section-bottom/ModuleCardCrypto";
import ModalImagePartners from "../../../components/admin-form/laplace/ModalImagePartners";
// icons svg
import IconBitcoin from "../../../assets/icon-bitcoin-white.svg";
import IconEthereum from "../../../assets/icon-ethereum-white.svg";
import IconBinance from "../../../assets/icon-binance-white.svg";
import IconTezos from "../../../assets/icon-tezos-white.svg";
// Bg Circles Styled
import {
    CircleTrending1,
    CircleTrending2,
    CircleTrending3,
    CircleTrending4,
    CircleTrending5
} from "../../../layouts/circles/StyledCirclesContainer4";

/* ------------ CONTAINER 4 - BOTTOM RIGHT ------------ */

export default function Container4({
    cryptocurrencies,
    imageModulePartners,
    setImageModulePartners,
    cardTitle,
    handleCardTitle
}) {
    /* Modal Module "Our Partners" */
    const [openModalPartners, setOpenModalPartners] = useState(false);
    const handleOpenModalPartners = () => {
        setOpenModalPartners(!openModalPartners);
    };

    return (
        <div className="container4-bottom-right">
            {/* -------- 4 small cards crytocurrency -------- */}
            <div className="container4-child-left">
                {/* "Bitcoin" */}
                <div className="container4-multicard-top-left container-child-style padding-small-card-top bg-color-small-card">
                    <ModuleCard
                        title={
                            cryptocurrencies !== null
                                ? cryptocurrencies[0].name
                                : "Cryptocurrency"
                        }
                        description="Price variation"
                    >
                        {cryptocurrencies !== null ? (
                            <ModuleCardCrypto
                                price_current={
                                    cryptocurrencies[0].price_current
                                }
                                price_lastweek={
                                    cryptocurrencies[0].price_lastweek
                                }
                                logo={IconBitcoin}
                            />
                        ) : (
                            "Aucune donnée disponible"
                        )}
                    </ModuleCard>
                </div>
                {/* "Ethereum" */}
                <div className="container4-multicard-top-right container-child-style padding-small-card-top bg-color-small-card">
                    <ModuleCard
                        title={
                            cryptocurrencies !== null
                                ? cryptocurrencies[1].name
                                : "Cryptocurrency"
                        }
                        description="Price variation"
                    >
                        {cryptocurrencies !== null ? (
                            <ModuleCardCrypto
                                price_current={
                                    cryptocurrencies[1].price_current
                                }
                                price_lastweek={
                                    cryptocurrencies[1].price_lastweek
                                }
                                logo={IconEthereum}
                            />
                        ) : (
                            "Aucune donnée disponible"
                        )}
                    </ModuleCard>
                </div>
                {/* "Binance" */}
                <div className="container4-multicard-bottom-right container-child-style padding-small-card-bottom bg-color-small-card">
                    <ModuleCard
                        title={
                            cryptocurrencies !== null
                                ? cryptocurrencies[2].name
                                : "Cryptocurrency"
                        }
                        description="Price variation"
                    >
                        {cryptocurrencies !== null ? (
                            <ModuleCardCrypto
                                price_current={
                                    cryptocurrencies[2].price_current
                                }
                                price_lastweek={
                                    cryptocurrencies[2].price_lastweek
                                }
                                logo={IconBinance}
                            />
                        ) : (
                            "Aucune donnée disponible"
                        )}
                    </ModuleCard>
                </div>
                {/* "Tezos" */}
                <div className="container4-multicard-bottom-left container-child-style padding-small-card-bottom bg-color-small-card">
                    <ModuleCard
                        title={
                            cryptocurrencies !== null
                                ? cryptocurrencies[3].name
                                : "Cryptocurrency"
                        }
                        description="Price variation"
                    >
                        {cryptocurrencies !== null ? (
                            <ModuleCardCrypto
                                price_current={
                                    cryptocurrencies[3].price_current
                                }
                                price_lastweek={
                                    cryptocurrencies[3].price_lastweek
                                }
                                logo={IconTezos}
                            />
                        ) : (
                            "Aucune donnée disponible"
                        )}
                    </ModuleCard>
                </div>
            </div>
            {/* ---- Large Card - "Trending Fintech" ---- */}
            {imageModulePartners !== null ? (
                <div className="container4-child-right container-child-style padding-big-card">
                    <ModuleCard
                        title="Our partners"
                        description="Network and ecosystem"
                        isEditable
                        handleOpenModal={handleOpenModalPartners}
                        handleCardTitle={handleCardTitle}
                    >
                        <CircleTrending1 />
                        <CircleTrending2 />
                        <CircleTrending3 />
                        <CircleTrending4 />
                        <CircleTrending5 />
                        <ModuleImage
                            image={imageModulePartners.src}
                            width={702.5}
                        />
                    </ModuleCard>
                    {/* ADMIN PART - Edit modal */}
                    {openModalPartners ? (
                        <div
                            className={
                                openModalPartners
                                    ? "modal_shown"
                                    : "modal_hidden"
                            }
                        >
                            <ModalImagePartners
                                handleOpenModalPartners={
                                    handleOpenModalPartners
                                }
                                cardTitle={cardTitle}
                                imageModulePartners={imageModulePartners}
                                setImageModulePartners={setImageModulePartners}
                            />
                        </div>
                    ) : null}
                </div>
            ) : (
                <div className="container4-child-right container-child-style padding-big-card">
                    <ModuleCard
                        title="Our partners"
                        description="Network and ecosystem"
                    >
                        <CircleTrending1 />
                        <CircleTrending2 />
                        <CircleTrending3 />
                        <CircleTrending4 />
                        <CircleTrending5 />
                        Aucune donnée disponible
                    </ModuleCard>
                </div>
            )}
        </div>
    );
}

Container4.propTypes = {
    cryptocurrencies: PropTypes.array,
    imageModulePartners: PropTypes.object,
    setImageModulePartners: PropTypes.func,
    cardTitle: PropTypes.string,
    handleCardTitle: PropTypes.func
};

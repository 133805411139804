import { Suspense, lazy } from "react";
import { Navigate, useRoutes } from "react-router-dom";
import { MainLayout } from "../layouts/main";
// components
import PersistLogin from "../pages/auth/PersistLogin";
import DashboardLaPlace from "../pages/laplace/DashboardLaPlace";
import GLEventsPartnersOffer from "../pages/glevents/GLEventsPartnersOffer";
import GLEventsPartnersOfferImages from "../pages/glevents/GLEventsPartnersOfferImages";

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) =>
    (
        // eslint-disable-next-line react-hooks/rules-of-hooks
        <Suspense fallback={<div>Loading... </div>}>
            <Component {...props} />
        </Suspense>
    );

export default function Router() {
    return useRoutes([
        {
            path: "login",
            children: [
                {
                    path: "",
                    element: <Login />
                }
            ]
        },
        {
            path: "",
            element: (
                <PersistLogin>
                    <MainLayout />
                </PersistLogin>
            ),
            children: [
                {
                    element: <Navigate to="/laplace" replace />,
                    index: true
                },
                {
                    path: "/laplace",
                    element: <DashboardLaPlace />
                },
                {
                    path: "/glevents/offre-partenaires",
                    element: <GLEventsPartnersOffer />
                },
                {
                    path: "/glevents/offre-partenaires/image/clientName",
                    element: <GLEventsPartnersOfferImages />
                }
            ]
        }
    ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import("../pages/auth/Login")));

import React from "react";
import PropTypes from "prop-types";
import * as Yup from "yup";
// Form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// Hooks
// import useAuth from "../../hooks/useAuth";
// component
import config from "../../../utils/config";
import { axiosPrivate } from "../../../api/axios";
// css
import "../ModalForm.css";

const ModalFormFintechInsights = ({
    handleOpenModalInsights,
    cardTitle,
    idVideo,
    setIdVideo
}) => {
    // const { setAuth } = useAuth();

    const VideoSchema = Yup.object().shape({
        idVideo: Yup.string().required("L'ID de la vidéo est requis.")
    });

    const {
        register,
        formState: { errors },
        handleSubmit
    } = useForm({
        mode: "onChange",
        defaultValues: { idVideo },
        resolver: yupResolver(VideoSchema)
    });

    //----------------------------------------------------------------------

    const onSubmit = async (values) => {
        try {
            const response = await axiosPrivate.post(
                `${config.BACK_URL}/admin/fintech-insights`,
                {
                    id_video: values.idVideo
                }
            );
            console.log(response);
            if (response.status === 200) {
                setIdVideo(response.data);
                handleOpenModalInsights();
            }
            // const { accessToken } = response.data;
            // const { roles } = response.data;
            // setAuth({ idVideo, roles, accessToken });
        } catch (error) {
            console.log(error);
        }
    };

    //----------------------------------------------------------------------

    return (
        <>
            <div className="modal_background">
                <div className="modal_card">
                    <h1 className="title">Edit Form</h1>
                    <h3 className="subtitle">{cardTitle}</h3>
                    <button
                        className="close_button"
                        type="button"
                        onClick={() => {
                            handleOpenModalInsights();
                        }}
                    >
                        <i className="fa-regular fa-circle-xmark fa-2xl" />
                    </button>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <p className="label">ID de la vidéo Youtube :</p>
                        <input
                            type="text"
                            id="idVideo"
                            {...register("idVideo")}
                            defaultValue={idVideo}
                        />
                        {errors.idVideo && (
                            <p className="error-message">
                                {errors.idVideo.message}
                            </p>
                        )}
                        <button className="submit_button" type="submit">
                            Save
                        </button>
                    </form>
                </div>
            </div>
        </>
    );
};

export default ModalFormFintechInsights;

ModalFormFintechInsights.propTypes = {
    handleOpenModalInsights: PropTypes.func,
    cardTitle: PropTypes.string,
    idVideo: PropTypes.string,
    setIdVideo: PropTypes.func
};

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { theme } from "../../theme";
import { fNoSymbolCurrency } from "../../utils/formatNumber";

const StyledContent = styled.div`
    display: flex;
    margin-top: 20px;
    height: 100%;
    justify-content: space-between;
`;

const Divider = styled.hr`
    width: 30%;
`;
// N
const DataContent = styled.h3`
    text-transform: uppercase;
    font-weight: 600;
    font-size: 30px;
    margin: 5px 0px;
`;
const Sentence = styled.p`
    margin: 1px 0px 0px 0px;
    font-size: 17px;
    font-weight: 600;
    color: ${theme.palette.primary.lighter};
`;
const Source = styled.p`
    font-size: 14px;
    font-style: italic;
    opacity: 0.7;
    margin: 0px;
`;
// N-1
const DataContentN1 = styled.h3`
    text-transform: uppercase;
    font-weight: 600;
    font-size: 26px;
    margin: 5px 0px;
`;
const SentenceN1 = styled.p`
    margin: 1px 0px 0px 0px;
    font-size: 15px;
    font-weight: 600;
    color: ${theme.palette.primary.lighter};
`;
const SourceN1 = styled.p`
    font-size: 13px;
    font-style: italic;
    opacity: 0.7;
    margin: 0px;
`;

// ---------------------------------------------------------

const ModuleVC = ({
    contentN_1,
    sentenceN_1,
    sourceN_1,
    content_N,
    sentence_N,
    source_N
}) => (
    <StyledContent>
        {/* DATA N */}
        <>
            <DataContent>
                {`${fNoSymbolCurrency(content_N)} M€` ||
                    "En attente de données"}
            </DataContent>
            <Sentence>{sentence_N || ""}</Sentence>
            <Source>Source : {source_N || "Aucune source"}</Source>
        </>
        <Divider />
        {/* DATA N-1 */}
        <>
            <DataContentN1>
                {`${fNoSymbolCurrency(contentN_1)} M€` ||
                    "En attente de données"}
            </DataContentN1>
            <SentenceN1>{sentenceN_1 || ""}</SentenceN1>
            <SourceN1>Source : {sourceN_1 || "Aucune source"}</SourceN1>
        </>
    </StyledContent>
);

export default ModuleVC;

ModuleVC.propTypes = {
    contentN_1: PropTypes.string,
    sentenceN_1: PropTypes.string,
    sourceN_1: PropTypes.string,
    content_N: PropTypes.string,
    sentence_N: PropTypes.string,
    source_N: PropTypes.string
};

import axios from "axios";
import config from "../utils/config";
import useAuth from "./useAuth";

const useLogout = () => {
    const { setAuth } = useAuth();

    const logout = async () => {
        setAuth({});
        try {
            const response = await axios.delete(
                `${config.BACK_URL}auth/logout`,
                {
                    withCredentials: true
                }
            );
        } catch (err) {
            console.error(err);
        }
    };

    return logout;
};

export default useLogout;

import styled from "styled-components";
import { theme } from "../../theme";

// CARD FINTECH INSIGHS
export const CircleInsight1 = styled.span`
    border-radius: 50%;
    border: 6px solid ${theme.palette.secondary.main};
    z-index: 0;
    height: 200px;
    width: 200px;
    position: absolute;
    top: -178px;
    left: 510px;
    opacity: 0.6;
`;
export const CircleInsight2 = styled.span`
    border-radius: 50%;
    background-color: ${theme.palette.quaternary.main};
    z-index: 0;
    height: 130px;
    width: 130px;
    position: absolute;
    top: -135px;
    right: -55px;
    opacity: 0.6;
`;
export const CircleInsight3 = styled.span`
    border-radius: 50%;
    border: 10px double ${theme.palette.secondary.main};
    z-index: 1;
    height: 70px;
    width: 70px;
    position: absolute;
    bottom: 20px;
    left: 70px;
    opacity: 0.6;
`;
export const CircleInsight4 = styled.span`
    border-radius: 50%;
    border: 6px dashed ${theme.palette.tertiary.main};
    z-index: 0;
    height: 430px;
    width: 430px;
    position: absolute;
    top: -250px;
    left: -160px;
    opacity: 0.6;
`;
export const CircleInsight5 = styled.span`
    border-radius: 50%;
    background-color: ${theme.palette.primary.main};
    z-index: 0;
    height: 400px;
    width: 400px;
    position: absolute;
    bottom: -220px;
    right: 30px;
    opacity: 0.6;
`;

// CARD TWITTER
export const CircleTwitter1 = styled.span`
    border-radius: 50%;
    border: 7px dotted ${theme.palette.primary.main};
    z-index: 1;
    height: 250px;
    width: 250px;
    position: absolute;
    top: -80px;
    left: 300px;
    opacity: 0.6;
`;
export const CircleTwitter2 = styled.span`
    border-radius: 50%;
    background-color: ${theme.palette.primary.main};
    z-index: 0;
    height: 50px;
    width: 50px;
    position: absolute;
    bottom: -30px;
    left: 405px;
    opacity: 0.6;
`;
export const CircleTwitter3 = styled.span`
    border-radius: 50%;
    background-color: ${theme.palette.secondary.main};
    z-index: 0;
    height: 200px;
    width: 200px;
    position: absolute;
    top: -160px;
    right: 0px;
    opacity: 0.6;
`;
export const CircleTwitter4 = styled.span`
    border-radius: 50%;
    border: 12px dotted ${theme.palette.quaternary.main};
    z-index: 0;
    height: 140px;
    width: 140px;
    position: absolute;
    bottom: -50px;
    right: 405px;
    opacity: 0.6;
`;

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const DataContent = styled.h3`
    font-weight: 600;
    font-size: 40px;
    margin: 0px;
`;
const Sentence = styled.p`
    text-align: center;
`;
const Source = styled.p`
    margin: 0px 0px;
    font-size: 14px;
    font-style: italic;
    opacity: 0.8;
    position: absolute;
    bottom: 0px;
`;

const ModuleCardData = ({ amount, sentence, source }) => (
    <>
        <DataContent>{amount || "En attente de données"}</DataContent>
        <Sentence>{sentence || "En attente de données"}</Sentence>
        <Source>Source : {source || "Aucune source"}</Source>
    </>
);

export default ModuleCardData;

ModuleCardData.propTypes = {
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    sentence: PropTypes.string,
    source: PropTypes.string
};

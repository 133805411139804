import React from "react";
import { TwitterTimelineEmbed } from "react-twitter-embed";
// css
import "./ModuleTwitter.css";

const ModuleTwitter = () => (
    <TwitterTimelineEmbed
        sourceType="profile"
        screenName="LaPlaceFintech"
        options={{ height: 600 }}
        tweetLimit={1}
        noFooter
        noHeader
    />
    // <a
    //     data-chrome="noheader, nofooter, noborders, transparent, noscrollbar"
    //     data-tweet-limit="1"
    //     className="twitter-timeline"
    //     href="https://twitter.com/LaPlaceFintech?ref_src=twsrc%5Etfw"
    // >
    //     Loading data...
    // </a>
);

export default ModuleTwitter;

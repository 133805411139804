import styled from "styled-components";
import { theme } from "../../theme";

// CARD MAPPING VC PORTFOLIO
export const CircleMapping1 = styled.span`
    border-radius: 50%;
    border: 6px solid ${theme.palette.quaternary.main};
    z-index: 0;
    height: 600px;
    width: 600px;
    position: absolute;
    top: -400px;
    right: -460px;
    opacity: 0.6;
`;
export const CircleMapping2 = styled.span`
    border-radius: 50%;
    background-color: ${theme.palette.secondary.main};
    z-index: 0;
    height: 40px;
    width: 40px;
    position: absolute;
    top: -80px;
    right: 10px;
    opacity: 0.6;
`;
export const CircleMapping3 = styled.span`
    border-radius: 50%;
    border: 4px dashed ${theme.palette.primary.main};
    z-index: 0;
    height: 300px;
    width: 300px;
    position: absolute;
    bottom: -230px;
    left: -80px;
    opacity: 0.6;
`;

import React from "react";
import PropTypes from "prop-types";
import * as Yup from "yup";
// Form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// Hooks
// import useAuth from "../../hooks/useAuth";
// component
import config from "../../../utils/config";
import { axiosPrivate } from "../../../api/axios";
// css
import "../ModalForm.css";

const ModalGLEventsVideo2 = ({ handleOpenModalVideo2, videos, setVideos }) => {
    // const { setAuth } = useAuth();

    const VideosSchema = Yup.object().shape({
        id_video_1: Yup.string().required("L'ID de la vidéo est requis."),
        id_video_2: Yup.string().required("L'ID de la vidéo est requis.")
    });

    const {
        register,
        formState: { errors },
        handleSubmit
    } = useForm({
        mode: "onChange",
        defaultValues: {
            id_video_1: videos.id_video_1,
            id_video_2: videos.id_video_2
        },
        resolver: yupResolver(VideosSchema)
    });

    //----------------------------------------------------------------------

    const onSubmit = async (values) => {
        try {
            const response = await axiosPrivate.post(
                `${config.BACK_URL}/admin/gle-partners-videos`,
                values
            );
            if (response.status === 200) {
                setVideos(values);
                handleOpenModalVideo2();
            }
            // const { accessToken } = response.data;
            // const { roles } = response.data;
            // setAuth({ idVideo, roles, accessToken });
        } catch (error) {
            console.log(error);
        }
    };

    //----------------------------------------------------------------------

    return (
        <>
            <div className="modal_background">
                <div className="modal_card">
                    <h1 className="title">Edit Form</h1>
                    <h3 className="subtitle">Edit video Youtube</h3>
                    <button
                        className="close_button"
                        type="button"
                        onClick={() => {
                            handleOpenModalVideo2();
                        }}
                    >
                        <i className="fa-regular fa-circle-xmark fa-2xl" />
                    </button>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <input
                            type="hidden"
                            id="id_video_1"
                            disabled
                            {...register("id_video_1")}
                        />
                        {errors.id_video_1 && (
                            <p className="error-message">
                                {errors.id_video_1.message}
                            </p>
                        )}
                        <p className="label">ID de la vidéo 2 Youtube :</p>
                        <input
                            type="text"
                            id="id_video_2"
                            {...register("id_video_2")}
                        />
                        {errors.id_video_2 && (
                            <p className="error-message">
                                {errors.id_video_2.message}
                            </p>
                        )}
                        <button className="submit_button" type="submit">
                            Save
                        </button>
                    </form>
                </div>
            </div>
        </>
    );
};

export default ModalGLEventsVideo2;

ModalGLEventsVideo2.propTypes = {
    handleOpenModalVideo2: PropTypes.func,
    videos: PropTypes.object,
    setVideos: PropTypes.func
};

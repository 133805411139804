import React from "react";
import PropTypes from "prop-types";
import * as Yup from "yup";
// Form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// Hooks
// import useAuth from "../../../hooks/useAuth";
// component
import config from "../../../utils/config";
import { axiosPrivate } from "../../../api/axios";
// css
import "../ModalForm.css";

const ModalFintechDatabase = ({
    handleOpenModalDatabase,
    cardTitle,
    fintechDatabase,
    setFintechDatabase
}) => {
    // const { setAuth } = useAuth();

    const DatabaseSchema = Yup.object().shape({
        amount: Yup.string().required("Une donnée est requise"),
        sentence: Yup.string().required("Une donnée est requise"),
        source: Yup.string().required("Une donnée est requise")
    });

    const {
        register,
        formState: { errors },
        handleSubmit
    } = useForm({
        mode: "onChange",
        defaultValues: {
            amount: fintechDatabase.amount,
            sentence: fintechDatabase.sentence,
            source: fintechDatabase.source
        },
        resolver: yupResolver(DatabaseSchema)
    });

    //----------------------------------------------------------------------

    const onSubmit = async (values) => {
        try {
            const response = await axiosPrivate.post(
                `${config.BACK_URL}/admin/fintech-database`,
                values
            );

            if (response.status === 200) {
                setFintechDatabase(values);
                handleOpenModalDatabase();
            }
            // const { accessToken } = response.data;
            // const { roles } = response.data;
            // setAuth({ amount, roles, accessToken });
        } catch (error) {
            console.log(error);
        }
    };

    //----------------------------------------------------------------------

    return (
        <>
            <div className="modal_background">
                <div className="modal_card">
                    <h1 className="title">Edit Form</h1>
                    <h3 className="subtitle">{cardTitle}</h3>
                    <button
                        className="close_button"
                        type="button"
                        onClick={() => {
                            handleOpenModalDatabase();
                        }}
                    >
                        <i className="fa-regular fa-circle-xmark fa-2xl" />
                    </button>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <p className="label">Nombre d'entreprises :</p>
                        <input
                            type="text"
                            id="amount"
                            {...register("amount")}
                        />
                        {errors.amount && (
                            <p className="error-message">
                                {errors.amount.message}
                            </p>
                        )}
                        <p className="label">Légende :</p>
                        <input
                            type="text"
                            id="sentence"
                            {...register("sentence")}
                        />
                        {errors.sentence && (
                            <p className="error-message">
                                {errors.sentence.message}
                            </p>
                        )}
                        <p className="label">Source :</p>
                        <input
                            type="text"
                            id="source"
                            {...register("source")}
                        />
                        {errors.source && (
                            <p className="error-message">
                                {errors.source.message}
                            </p>
                        )}
                        <button className="submit_button" type="submit">
                            Save
                        </button>
                    </form>
                </div>
            </div>
        </>
    );
};

export default ModalFintechDatabase;

ModalFintechDatabase.propTypes = {
    handleOpenModalDatabase: PropTypes.func,
    cardTitle: PropTypes.string,
    fintechDatabase: PropTypes.object,
    setFintechDatabase: PropTypes.func
};

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { theme } from "../../theme";
// component
import CircuitSvg from "../../assets/bg-circuit.svg";

const ContentMapping = styled.div`
    display: flex;
    justify-content: center;
`;

const LogoVC = styled.img`
    width: 27%;
    margin-bottom: 2px;
    border-bottom: 1px dotted ${theme.palette.secondary.lighter};
`;

const CircuitBg = styled.img`
    position: absolute;
    height: 100%;
`;

const ContentPortfolio = styled.div`
    display: flex;
    align-content: center;
    flex-wrap: wrap;
    flex-direction: row;
    height: 500px;
`;

const LogoPortfolio = styled.img`
    height: 42px;
    margin: 70px 50px;
`;

const MappingVC = ({ companies }) => (
    <ContentMapping>
        {companies.map((company) =>
            company.is_owner === 1 ? (
                <LogoVC src={company.src} alt="Logo VC" key={company.id} />
            ) : null
        )}
        <ContentPortfolio>
            <CircuitBg src={CircuitSvg} alt="Image circuit background" />
            {companies.map((company) =>
                company.is_owner === 2 ? (
                    <LogoPortfolio
                        src={company.src}
                        alt="Logo Portfolio"
                        key={company.id}
                    />
                ) : null
            )}
        </ContentPortfolio>
    </ContentMapping>
);

export default MappingVC;

MappingVC.propTypes = {
    companies: PropTypes.array
};

import React from "react";
import PropTypes from "prop-types";

const ModuleVideo = ({ idVideo, width, height }) => (
    <div>
        <iframe
            width={width}
            height={height}
            src={`https://www.youtube.com/embed/${idVideo}?controls=0&autoplay=1&mute=1&loop=1&playlist=${idVideo}&iv_load_policy=0&showinfo=0&modestbranding=1`}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
        />
    </div>
);

export default ModuleVideo;

ModuleVideo.propTypes = {
    idVideo: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

import React from "react";
import PropTypes from "prop-types";

const ModuleImage = ({ image, alt, width }) => (
    <div>
        <img src={image} alt={alt} width={width} />
    </div>
);

export default ModuleImage;

ModuleImage.propTypes = {
    image: PropTypes.string,
    alt: PropTypes.string,
    width: PropTypes.number
};

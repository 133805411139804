import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
// css
import "./DashboardLaPlace.css";
// components
import config from "../../utils/config";
import logoLaPlace from "../../assets/logo-laplace-light.svg";
import MainSidebar from "../../layouts/main/MainSidebar";
import Container1 from "./containers/Container1";
import Container2 from "./containers/Container2";
import Container3 from "./containers/Container3";
import Container4 from "./containers/Container4";

export default function DashboardLaPlace() {
    // state list of all modules data get in the db
    const [modulesData, setModulesData] = useState([]);
    // individual state of each modules data
    const [idVideo, setIdVideo] = useState(null);
    const [weeklyData, setWeeklyData] = useState(null);
    const [vcInvestment, setVcInvestment] = useState(null);
    const [fintechDatabase, setFintechDatabase] = useState(null);
    const [nbAdherents, setNbAdherents] = useState(null);

    // all images of modules
    const [images, setImages] = useState(null);
    // individual state of each modules images
    const [imageMarketAnalysis, setImageMarketAnalysis] = useState(null);
    const [imageModulePartners, setImageModulePartners] = useState(null);

    // state list of all cryptocurrencies get in the db
    const [cryptocurrencies, setCryptocurrencies] = useState(null);
    // state list of all companies get in the db
    const [companies, setCompanies] = useState(null);

    /* Modal */
    const [cardTitle, setCardTitle] = useState("");
    const handleCardTitle = (title) => {
        setCardTitle(title);
    };

    //----------------------------------------------------------------------

    const requestModulesData = axios.get(
        `${config.BACK_URL}laplace/modules-data`
    );
    const requestCrypto = axios.get(`${config.BACK_URL}laplace/cryptocurrency`);
    const requestCompanies = axios.get(`${config.BACK_URL}laplace/company`);
    const requestImages = axios.get(`${config.BACK_URL}images/images`);

    useEffect(() => {
        axios
            .all([
                requestModulesData,
                requestCrypto,
                requestCompanies,
                requestImages
            ])
            .then((res) => {
                const responseModulesData = res[0].data;
                const responseCrypto = res[1].data;
                const responseCompanies = res[2].data;
                const responseImages = res[3].data;
                setModulesData(responseModulesData);
                setCryptocurrencies(responseCrypto);
                setCompanies(responseCompanies);
                setImages(responseImages);
            })
            .catch((error) => {
                console.log(error);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //----------------------------------------------------------------------

    const getModulesData = useCallback(() => {
        if (modulesData !== null) {
            modulesData.forEach((el) => {
                switch (el.name) {
                    case "id_video":
                        setIdVideo(el.content);
                        break;
                    case "weekly_data":
                        setWeeklyData(el.content);
                        break;
                    case "vc_investment":
                        setVcInvestment(el.content);
                        break;
                    case "fintech_database":
                        setFintechDatabase(el.content);
                        break;
                    case "nb_adherents":
                        setNbAdherents(el.content);
                        break;
                    default:
                        break;
                }
            });
        }
    }, [modulesData]);

    const getModulesImages = useCallback(() => {
        if (images !== null) {
            images.forEach((el) => {
                switch (el.type_name) {
                    case "Module Market Analysis":
                        setImageMarketAnalysis(el);
                        break;
                    case "Module Our Partners":
                        setImageModulePartners(el);
                        break;
                    default:
                        break;
                }
            });
        }
    }, [images]);

    useEffect(() => {
        if (modulesData && images !== null) {
            getModulesData();
            getModulesImages();
        }
    }, [modulesData, getModulesData, images, getModulesImages]);

    //----------------------------------------------------------------------

    return (
        // * -------- Main page containers -------- *
        <div className="main-container-laplace">
            {/* Left Sidebar */}
            <div className="sidebar-laplace sidebar-color">
                <MainSidebar logoOnTop={logoLaPlace} widthLogoOnTop="175px" />
            </div>
            <div className="main-half-container-top bg-animation">
                {/* Container 1 - Top Left */}
                <Container1
                    idVideo={idVideo}
                    setIdVideo={setIdVideo}
                    cardTitle={cardTitle}
                    handleCardTitle={handleCardTitle}
                />

                {/* Container 2 - Top Right */}
                <Container2
                    weeklyData={weeklyData}
                    setWeeklyData={setWeeklyData}
                    vcInvestment={vcInvestment}
                    setVcInvestment={setVcInvestment}
                    nbAdherents={nbAdherents}
                    fintechDatabase={fintechDatabase}
                    setFintechDatabase={setFintechDatabase}
                    companies={companies}
                    cardTitle={cardTitle}
                    handleCardTitle={handleCardTitle}
                />
            </div>

            <div className="main-half-container-bottom bg-animation">
                {/* Container 3 - Bottom Left */}
                <Container3
                    companies={companies}
                    setCompanies={setCompanies}
                    imageMarketAnalysis={imageMarketAnalysis}
                    setImageMarketAnalysis={setImageMarketAnalysis}
                    cardTitle={cardTitle}
                    handleCardTitle={handleCardTitle}
                />

                {/* Container 4 - Bottom Right */}
                <Container4
                    cryptocurrencies={cryptocurrencies}
                    imageModulePartners={imageModulePartners}
                    setImageModulePartners={setImageModulePartners}
                    cardTitle={cardTitle}
                    handleCardTitle={handleCardTitle}
                />
            </div>
        </div>
    );
}

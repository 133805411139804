import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
// components
import useAuth from "../../hooks/useAuth";
import InvyoSvg from "../../assets/logo-invyo-light.svg";
import LogoutForm from "../../components/admin-form/LogoutForm";

const TextPoweredBy = styled.p`
    color: white;
    font-size: 15px;
    letter-spacing: 2px;
`;
const LogoInvyo = styled.img`
    width: 125px;
`;
const LogoEditable = styled.img`
    width: 83%;
`;

const MainSidebar = ({
    logoOnTop,
    widthLogoOnTop,
    logoEditable,
    isEditable,
    handleOpenModalLogo
}) => {
    const { auth } = useAuth();
    return (
        <>
            <div className="logo">
                <img
                    src={logoOnTop}
                    alt="logo-gl-events"
                    width={widthLogoOnTop}
                />
            </div>

            <LogoutForm />

            <div className="logo">
                {logoEditable && (
                    <LogoEditable src={logoEditable} alt="logo-company" />
                )}
                {isEditable && auth?.roles === "admin" ? (
                    <button
                        className="edit-button-gl-events-global edit-button-gl-events-sidebar edit-button-blue"
                        type="button"
                        onClick={() => {
                            handleOpenModalLogo();
                        }}
                    >
                        {logoEditable ? (
                            <i className="fa-solid fa-pen-to-square fa-xl" />
                        ) : (
                            <i className="fa-solid fa-plus fa-xl" />
                        )}
                    </button>
                ) : null}
            </div>

            <div className="logo">
                <TextPoweredBy>Powered by</TextPoweredBy>
                <LogoInvyo src={InvyoSvg} alt="logo-invyo" />
            </div>
        </>
    );
};

export default MainSidebar;

MainSidebar.propTypes = {
    logoOnTop: PropTypes.string,
    widthLogoOnTop: PropTypes.string,
    logoEditable: PropTypes.string,
    isEditable: PropTypes.bool,
    handleOpenModalLogo: PropTypes.func
};

import React, { useState } from "react";
import PropTypes from "prop-types";
// components
import ModuleCard from "../../../components/main/ModuleCard";
import ModuleGradingFunding from "../../../components/section-bottom/ModuleGradingFunding";
import ModuleImage from "../../../components/main/ModuleImage";
import ModalFormAddCompanyFundings from "../../../components/admin-form/laplace/ModalFormAddCompanyFundings";
import ModalImageMarketAnalysis from "../../../components/admin-form/laplace/ModalImageMarketAnalysis";
// Bg Circles Styled
import {
    CircleFunding1,
    CircleFunding2,
    CircleFunding3,
    CircleMarket1,
    CircleMarket2,
    CircleMarket3,
    CircleMarket4
} from "../../../layouts/circles/StyledCirclesContainer3";
import ModalFormEditCompanyFundings from "../../../components/admin-form/laplace/ModalFormEditCompanyFundings.js";

/* ------------ CONTAINER 3 - BOTTOM LEFT ------------ */

export default function Container3({
    companies,
    setCompanies,
    imageMarketAnalysis,
    setImageMarketAnalysis,
    cardTitle,
    handleCardTitle
}) {
    /* Modal Module "Market Analysis" */
    const [openModalAnalysis, setOpenModalAnalysis] = useState(false);
    const handleOpenModalAnalysis = () => {
        setOpenModalAnalysis(!openModalAnalysis);
    };
    /* Modal Module "Levée de Fond" */
    // Add a new company
    const [openModalAddCompanyFundings, setOpenModalAddCompanyFundings] =
        useState(false);
    const handleOpenModalAddCompanyFundings = () => {
        setOpenModalAddCompanyFundings(!openModalAddCompanyFundings);
    };
    // Edit company
    const [openModalEditCompanyFundings, setOpenModalEditCompanyFundings] =
        useState(false);
    const handleOpenModalEditCompanyFundings = () => {
        setOpenModalEditCompanyFundings(!openModalEditCompanyFundings);
    };

    return (
        <div className="container3-bottom-left">
            {/* "Top 3 Fundings" */}
            <div className="container3-child-left container-child-style padding-big-card">
                <ModuleCard
                    title="Levée de fonds"
                    description="Top 3 - septembre 2022"
                    isEditable
                    isAddableCompany
                    handleOpenModal={handleOpenModalEditCompanyFundings}
                    handleOpenModalAddCompanyFundings={
                        handleOpenModalAddCompanyFundings
                    }
                    handleCardTitle={handleCardTitle}
                >
                    <CircleFunding1 />
                    <CircleFunding2 />
                    <CircleFunding3 />
                    {companies !== null ? (
                        <ModuleGradingFunding companies={companies} />
                    ) : (
                        "Aucune donnée disponible"
                    )}
                </ModuleCard>
                {/* ADMIN PART - Add a new company modal */}
                {openModalAddCompanyFundings ? (
                    <div
                        className={
                            openModalAddCompanyFundings
                                ? "modal_shown"
                                : "modal_hidden"
                        }
                    >
                        <ModalFormAddCompanyFundings
                            handleOpenModalAddCompanyFundings={
                                handleOpenModalAddCompanyFundings
                            }
                            cardTitle={cardTitle}
                            companies={companies}
                            setCompanies={setCompanies}
                        />
                    </div>
                ) : null}
                {/* ADMIN PART - Edit a company modal */}
                {openModalEditCompanyFundings ? (
                    <div
                        className={
                            openModalEditCompanyFundings
                                ? "modal_shown"
                                : "modal_hidden"
                        }
                    >
                        <ModalFormEditCompanyFundings
                            handleOpenModalEditCompanyFundings={
                                handleOpenModalEditCompanyFundings
                            }
                            cardTitle={cardTitle}
                            companies={companies}
                            setCompanies={setCompanies}
                        />
                    </div>
                ) : null}
            </div>
            {/* "Market Analysis" */}
            {imageMarketAnalysis !== null ? (
                <div className="container3-child-right container-child-style padding-big-card">
                    <ModuleCard
                        title="Market Analysis"
                        description="Monitoring Industry Trends 2022"
                        isEditable
                        handleOpenModal={handleOpenModalAnalysis}
                        handleCardTitle={handleCardTitle}
                    >
                        <CircleMarket1 />
                        <CircleMarket2 />
                        <CircleMarket3 />
                        <CircleMarket4 />
                        <ModuleImage
                            /* image={imageMarketAnalysis.src} */
                            image="/static/laplace-qr.png"
                            width={702.5}
                        />
                    </ModuleCard>
                    {/* ADMIN PART - Edit modal */}
                    {openModalAnalysis ? (
                        <div
                            className={
                                openModalAnalysis
                                    ? "modal_shown"
                                    : "modal_hidden"
                            }
                        >
                            <ModalImageMarketAnalysis
                                handleOpenModalAnalysis={
                                    handleOpenModalAnalysis
                                }
                                cardTitle={cardTitle}
                                imageMarketAnalysis={imageMarketAnalysis}
                                setImageMarketAnalysis={setImageMarketAnalysis}
                            />
                        </div>
                    ) : null}
                </div>
            ) : (
                <div className="container3-child-right container-child-style padding-big-card">
                    <ModuleCard
                        title="Market Analysis"
                        description="Monitoring Industry Trends 2022"
                    >
                        <CircleMarket1 />
                        <CircleMarket2 />
                        <CircleMarket3 />
                        <CircleMarket4 />
                        Aucune donnée disponible
                    </ModuleCard>
                </div>
            )}
        </div>
    );
}

Container3.propTypes = {
    companies: PropTypes.array,
    setCompanies: PropTypes.func,
    imageMarketAnalysis: PropTypes.object,
    setImageMarketAnalysis: PropTypes.func,
    cardTitle: PropTypes.string,
    handleCardTitle: PropTypes.func
};
